import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/home/circleci/sleuth/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/home/circleci/sleuth/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/home/circleci/sleuth/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/home/circleci/sleuth/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/home/circleci/sleuth/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/home/circleci/sleuth/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/circleci/sleuth/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/home/circleci/sleuth/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_wyfMiPhAu5MxJ5fJSqEG5ZOO2j6rcPt8TwZKSttPTG4 from "/home/circleci/sleuth/frontend/node_modules/@hexdigital/nuxt-segment/dist/runtime/plugin.client.mjs";
import slideovers_g1RyjnFCC2XCqS3NLpv7yngCZ5AzjpmT7UJr_dW3BbY from "/home/circleci/sleuth/frontend/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_JhH8M1KzF3pQyhcHsoNTBLd8tKGet6zo2zTBVDe7nK4 from "/home/circleci/sleuth/frontend/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_E7kSti5pGZ28QhUUurq6gGRU3l65WuXO_KJC3GQgzFo from "/home/circleci/sleuth/frontend/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg from "/home/circleci/sleuth/frontend/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/home/circleci/sleuth/frontend/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import _0_auth_EFSEBFvBOtJiaQFc4T_W2Ibbee5V7G0eNg2GyO4pq_w from "/home/circleci/sleuth/frontend/auth/plugins/0.auth.ts";
import _01_websocket_client_8eWZVRVEAwnQxIzBm8fE9nWh3kKYUhXFRCt5gy433NA from "/home/circleci/sleuth/frontend/plugins/01.websocket.client.ts";
import _02_app_updated_client_FNvpNpFvuXPdSbkin59UIodM_CCjaTQOYOWRzPX9nXM from "/home/circleci/sleuth/frontend/plugins/02.app-updated.client.ts";
import announcekit_client_duq45RvRQCA0FaayHHl3It8PT6lYwjEzgSbtQqKZ4mg from "/home/circleci/sleuth/frontend/plugins/announcekit.client.js";
import directives_client_c6C68lzhz9jkgZGGhLWgdden_tJYVgNhvOJkymhityg from "/home/circleci/sleuth/frontend/plugins/directives.client.ts";
import posthog_client_k_93ax9wvjMk273QaphCGslBGMEGAt2ipswJ9W8luZA from "/home/circleci/sleuth/frontend/plugins/posthog.client.js";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/home/circleci/sleuth/frontend/plugins/sentry.client.ts";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_client_wyfMiPhAu5MxJ5fJSqEG5ZOO2j6rcPt8TwZKSttPTG4,
  slideovers_g1RyjnFCC2XCqS3NLpv7yngCZ5AzjpmT7UJr_dW3BbY,
  modals_JhH8M1KzF3pQyhcHsoNTBLd8tKGet6zo2zTBVDe7nK4,
  colors_E7kSti5pGZ28QhUUurq6gGRU3l65WuXO_KJC3GQgzFo,
  plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  _0_auth_EFSEBFvBOtJiaQFc4T_W2Ibbee5V7G0eNg2GyO4pq_w,
  _01_websocket_client_8eWZVRVEAwnQxIzBm8fE9nWh3kKYUhXFRCt5gy433NA,
  _02_app_updated_client_FNvpNpFvuXPdSbkin59UIodM_CCjaTQOYOWRzPX9nXM,
  announcekit_client_duq45RvRQCA0FaayHHl3It8PT6lYwjEzgSbtQqKZ4mg,
  directives_client_c6C68lzhz9jkgZGGhLWgdden_tJYVgNhvOJkymhityg,
  posthog_client_k_93ax9wvjMk273QaphCGslBGMEGAt2ipswJ9W8luZA,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE
]

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexqnu_45AxWuCFAtIzfzfF5SPDSS2w5_45wgUaN4Ttr5_huYYMeta } from "/home/circleci/sleuth/frontend/pages/index.vue?macro=true";
import { default as sign_45inRcFtNr8XsEZ7RHiDNTHDzFmQ3_45jzm_45dU8sd4Rjv_45xFEMeta } from "/home/circleci/sleuth/frontend/pages/sign-in.vue?macro=true";
import { default as sign_45upmCeQhReUuBLYjSuVke2gz_45B49cDVdnRjoh3pRYvCfyYMeta } from "/home/circleci/sleuth/frontend/pages/sign-up.vue?macro=true";
import { default as general14C8FQQxfk06kuXkVWHzmlkuDTK0RjYR6xehPLERJ6IMeta } from "/home/circleci/sleuth/frontend/pages/settings/user/general.vue?macro=true";
import { default as indexdgTu54HCKb06eqFlwLZpJEFOPfBGb_45H_m8_gPjKC8FQMeta } from "/home/circleci/sleuth/frontend/pages/settings/user/my/index.vue?macro=true";
import { default as externalhRqqKSQe6_45XO2LhltMb7L6wbcPcu85adK6KsvIM_TvEMeta } from "/home/circleci/sleuth/frontend/pages/settings/user/my/external.vue?macro=true";
import { default as notificationYtmshKaFnYIH0orIUeF6w3M0ycHwmH_og8EgOIFqiUsMeta } from "/home/circleci/sleuth/frontend/pages/settings/user/notification.vue?macro=true";
import { default as index_46generatedE3a31sYcl_fXg5_45rKrUT3G5hPmUnDidWitQOC60OHJAMeta } from "/home/circleci/sleuth/frontend/pages/settings/user/my/index.generated.ts?macro=true";
import { default as notification_46generatedKcMjD_xPYP7xk58v7tdFvxF_45L1Eqruc48ce5nb0g3mgMeta } from "/home/circleci/sleuth/frontend/pages/settings/user/notification.generated.ts?macro=true";
import { default as userQDvU83MyYNp1yImAHSKfK6drtw0um2xaevjPIQnw9AoMeta } from "/home/circleci/sleuth/frontend/pages/settings/user.vue?macro=true";
import { default as nameboW9DqxLGcC_WwRJSzg7huzNRlBwUVZKjCWxMyffmhkMeta } from "/home/circleci/sleuth/frontend/pages/teamspace/new/name.vue?macro=true";
import { default as name_46generatedPvLan7SwhQ7JgbFSDZWUohM4D8gVggQcS_seV9ZtrSoMeta } from "/home/circleci/sleuth/frontend/pages/teamspace/new/name.generated.ts?macro=true";
import { default as teams_45and_45membersP9LtzR2f1_Dchz3tnGGNnKMWwB4QgRYqGsC6_c45BW4Meta } from "/home/circleci/sleuth/frontend/pages/teamspace/new/teams-and-members.vue?macro=true";
import { default as indexH_jC7ii7yjykb35llZLymY6ey836bSp3gyCWi7YYVxwMeta } from "/home/circleci/sleuth/frontend/pages/teamspace/new/integrations/index.vue?macro=true";
import { default as indexFIWKgACIA7Zg9Cd_45qxPbLttU5fA6_SJ_YPuuRcawSHAMeta } from "/home/circleci/sleuth/frontend/pages/teamspace/new/integrations/[providerId]/index.vue?macro=true";
import { default as newVSLyjEauHwssktRMpnhNmWVJKIRlEl0uWSUGbh62LlwMeta } from "/home/circleci/sleuth/frontend/pages/teamspace/new.vue?macro=true";
import { default as delete_45accountWwrf_45Be7ZnkqB1PsLq12odCuLiXb2t4I_CE46AEOtUsMeta } from "/home/circleci/sleuth/frontend/pages/delete-account.vue?macro=true";
import { default as index_46generatedlkq_gFFRLe9cD78ze4vldWn61rbBqDbiURPlKORcUTIMeta } from "/home/circleci/sleuth/frontend/pages/index.generated.ts?macro=true";
import { default as settings_46generatedcI90a2EeRqAEpsFWjcFzC_45qgOQG5xHOA6lmArRTmTpYMeta } from "/home/circleci/sleuth/frontend/pages/settings.generated.ts?macro=true";
import { default as index0lzfM1AIdCqJ3UA_45M33vI23Ik_45EDC8FBZsyW5n2h_fUMeta } from "/home/circleci/sleuth/frontend/pages/reset-password/index.vue?macro=true";
import { default as aikd3fG8_45NqFHMqj3Vu1Zbxq8Qgv6I_45RTxg3fTP9B8yhcMeta } from "/home/circleci/sleuth/frontend/pages/settings/organization/ai.vue?macro=true";
import { default as indexvFAFq1MnIFbFGx24kPLY9HJlW8GmINJKxFMgORoFp1YMeta } from "/home/circleci/sleuth/frontend/pages/settings/organization/users/index.vue?macro=true";
import { default as indextwQzCpFISupx1UgeIaVuAj5r1N4K3ygXtmogJoPtpZMMeta } from "/home/circleci/sleuth/frontend/pages/settings/organization/users/[userId]/index.vue?macro=true";
import { default as externalkmKYlPDqU0dq6Flmsoo01jd9iFo7KlTbjQid9oxfwJIMeta } from "/home/circleci/sleuth/frontend/pages/settings/organization/users/[userId]/external.vue?macro=true";
import { default as index_46generatedQKA2sj6h6Uxz_45dh2v2WKq4OPbfdn3baXmYYZyNeyyu8Meta } from "/home/circleci/sleuth/frontend/pages/settings/organization/users/[userId]/index.generated.ts?macro=true";
import { default as external_46generatedt6gj4KPzEHBeeKkOZpQR5oImTYw_45MLuY_45H2USB4B0kcMeta } from "/home/circleci/sleuth/frontend/pages/settings/organization/users/[userId]/external.generated.ts?macro=true";
import { default as users4_45C3PeMMneZZICP8q_H_v5kGKf5BDx8IsyglfcxjW_cMeta } from "/home/circleci/sleuth/frontend/pages/settings/organization/users.vue?macro=true";
import { default as indexCh_6hxsgJ3xBjthdLKe8Tnbbo_453FrRx5FSUWcIELA2oMeta } from "/home/circleci/sleuth/frontend/pages/settings/organization/integrations/index.vue?macro=true";
import { default as index_46generatedTqbsjhlJ_45BT5vA4ELqAyFvyoGVkbWowHmYskQcFIQtYMeta } from "/home/circleci/sleuth/frontend/pages/settings/organization/integrations/index.generated.ts?macro=true";
import { default as indexXIp8s03DCSn0etcIHjPQ43_uQO6fuBFxzI2jdTJEUb8Meta } from "/home/circleci/sleuth/frontend/pages/settings/organization/integrations/[providerId]/index.vue?macro=true";
import { default as rules8lyWKmz1pMlKl2LnmeQmquE6EvltdCpencfzC39y5OgMeta } from "/home/circleci/sleuth/frontend/pages/settings/organization/integrations/[providerId]/rules.vue?macro=true";
import { default as index_46generated_45z6Zkx__45_45G1R9lECehxTR4HS6PqW4gdX5m8_dW0_45z2YMeta } from "/home/circleci/sleuth/frontend/pages/settings/organization/integrations/[providerId]/index.generated.ts?macro=true";
import { default as integrationsJ1SxEAUZgWK8v_lEz2a9O_450d6NxIaWHAYxlrwgGfo_454Meta } from "/home/circleci/sleuth/frontend/pages/settings/organization/integrations.vue?macro=true";
import { default as localization4YgxKhkVULetlURAWt5X9T5nmI5f6_45q04ZgF4LTO5b0Meta } from "/home/circleci/sleuth/frontend/pages/settings/organization/localization.vue?macro=true";
import { default as authenticationchdCMtmEdACrXPJkhREUT9KCBroGti00aTBHNGWybyYMeta } from "/home/circleci/sleuth/frontend/pages/settings/organization/authentication.vue?macro=true";
import { default as investment_45mixAqD_45eeUdB6xs9P0jIquOjEk8ubN8A0cz4ei5YWSbJsYMeta } from "/home/circleci/sleuth/frontend/pages/settings/organization/investment-mix.vue?macro=true";
import { default as organizationoZkLFStZpe96zgHl51Vs_45OPx_45VGarjH3l3U6VVSOttIMeta } from "/home/circleci/sleuth/frontend/pages/settings/organization.vue?macro=true";
import { default as _91reviewId_93W3B6pr4D2bisCSPvODCWRki81tIGCw7PqPevARg8xh0Meta } from "/home/circleci/sleuth/frontend/pages/slides/review/[reviewId].vue?macro=true";
import { default as reset_45successe3VF464s6lrVsCpE9foZGgVEe6OiUgUJhfv4bDIbI0MMeta } from "/home/circleci/sleuth/frontend/pages/reset-password/reset-success.vue?macro=true";
import { default as indexP00hu7MdXyT8k_45fjiGfQ8U1Ggu0cbmA4uQTKL1i8ZYoMeta } from "/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/index.vue?macro=true";
import { default as request_45success9Y9pyV88WkAHR1IvTk_45LEB7a1mde_lRaW7W2i4tKd3sMeta } from "/home/circleci/sleuth/frontend/pages/reset-password/request-success.vue?macro=true";
import { default as generale51gQiwAcbxIwkgw0MJwTHlaSyzF_wL4uYaB1HsKsrkMeta } from "/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/general.vue?macro=true";
import { default as investment_45mix2BkFeTxUX3_paHslfWz_uX_zTsEz9FsIc8wO6UD9h0sMeta } from "/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/investment-mix.vue?macro=true";
import { default as general_46generated00_T0gccxbUTaTZAr9Jvk62WqJqmrUOSi4pvG8VdIOwMeta } from "/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/general.generated.ts?macro=true";
import { default as teams_45and_45membersvJ6gs1zUIlhDnpjMA_SGWZ_bkbTz1wVg9XL00C_Md8QMeta } from "/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/teams-and-members.vue?macro=true";
import { default as indexzs5rJzxgba6snej1dpx8ZBmLtXX3E3X8CKDHh9NF_45FwMeta } from "/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/integrations/index.vue?macro=true";
import { default as teams_45and_45members_46generatedy8Mfcv_jSNPtz07FqcFznEjjKNZUXHULFEvRdW1N0a8Meta } from "/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/teams-and-members.generated.ts?macro=true";
import { default as indexAzjZDpZEuJCaX36OpaQ03lTstPBakJeEEJYvB6_45nkusMeta } from "/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/integrations/[providerId]/index.vue?macro=true";
import { default as _91teamspaceId_93PdHayYkssCBrZRZy81BLYkmKo0QXHHq5LfzUEz_cstcMeta } from "/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId].vue?macro=true";
import { default as set_45passwordZM1prtMaYFA9_2quNI9xNoOAbh4KsY9VcCz_fuVk8ZEMeta } from "/home/circleci/sleuth/frontend/pages/reset-password/[uid]/set-password.vue?macro=true";
import { default as indexmLCoceiFK0VUKspPe_45iCFa37YoaFUtgZ93pNUDM2KDMMeta } from "/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/reviews/index.vue?macro=true";
import { default as indexOC8aYFv7PHN3wcxZBlrDenRDWVKQC90EcZiihqZ3iUwMeta } from "/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/surveys/index.vue?macro=true";
import { default as indexqJIZ92w1EJohDf8bVR9Jphnx_45XgGeI6UwYfXPET5b_45sMeta } from "/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/dashboards/index.vue?macro=true";
import { default as _91teamspaceId_93_46generatedMOXwSrzKuvMwXCvK92CQ_4njT33V0EAITLPWymN9nmsMeta } from "/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId].generated.ts?macro=true";
import { default as indexcnJ1vOB492TR2rg4WoksFqP3Uy8l1uWf_45m0NKd7URJoMeta } from "/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/reviews/[reviewId]/index.vue?macro=true";
import { default as indexFx_1No_XmPUonTOhnTPwFnmMEGoIWbhKN8s9DFSQwy0Meta } from "/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/surveys/[surveyId]/index.vue?macro=true";
import { default as indexfetGZawPyl3dmCHrtmweOooBzSI3KKfVe92Kkzp6_nYMeta } from "/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/dashboards/[dashboardId]/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexqnu_45AxWuCFAtIzfzfF5SPDSS2w5_45wgUaN4Ttr5_huYYMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/index.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: sign_45inRcFtNr8XsEZ7RHiDNTHDzFmQ3_45jzm_45dU8sd4Rjv_45xFEMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/sign-in.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45upmCeQhReUuBLYjSuVke2gz_45B49cDVdnRjoh3pRYvCfyYMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/sign-up.vue")
  },
  {
    name: "settings-user",
    path: "/settings/user",
    meta: userQDvU83MyYNp1yImAHSKfK6drtw0um2xaevjPIQnw9AoMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/user.vue"),
    children: [
  {
    name: "settings-user-general",
    path: "general",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/user/general.vue")
  },
  {
    name: "settings-user-my",
    path: "my",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/user/my/index.vue")
  },
  {
    name: "settings-user-my-external",
    path: "my/external",
    meta: externalhRqqKSQe6_45XO2LhltMb7L6wbcPcu85adK6KsvIM_TvEMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/user/my/external.vue")
  },
  {
    name: "settings-user-notification",
    path: "notification",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/user/notification.vue")
  },
  {
    name: "settings-user-my-index.generated",
    path: "my/index.generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/user/my/index.generated.ts")
  },
  {
    name: "settings-user-notification.generated",
    path: "notification.generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/user/notification.generated.ts")
  }
]
  },
  {
    name: "teamspace-new",
    path: "/teamspace/new",
    meta: newVSLyjEauHwssktRMpnhNmWVJKIRlEl0uWSUGbh62LlwMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/new.vue"),
    children: [
  {
    name: "teamspace-new-name",
    path: "name",
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/new/name.vue")
  },
  {
    name: "teamspace-new-name.generated",
    path: "name.generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/new/name.generated.ts")
  },
  {
    name: "teamspace-new-teams-and-members",
    path: "teams-and-members",
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/new/teams-and-members.vue")
  },
  {
    name: "teamspace-new-integrations",
    path: "integrations",
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/new/integrations/index.vue")
  },
  {
    name: "teamspace-new-integrations-providerId",
    path: "integrations/:providerId()",
    meta: indexFIWKgACIA7Zg9Cd_45qxPbLttU5fA6_SJ_YPuuRcawSHAMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/new/integrations/[providerId]/index.vue")
  }
]
  },
  {
    name: "delete-account",
    path: "/delete-account",
    component: () => import("/home/circleci/sleuth/frontend/pages/delete-account.vue")
  },
  {
    name: "index.generated",
    path: "/index.generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/index.generated.ts")
  },
  {
    name: "settings.generated",
    path: "/settings.generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings.generated.ts")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: index0lzfM1AIdCqJ3UA_45M33vI23Ik_45EDC8FBZsyW5n2h_fUMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/reset-password/index.vue")
  },
  {
    name: "settings-organization",
    path: "/settings/organization",
    meta: organizationoZkLFStZpe96zgHl51Vs_45OPx_45VGarjH3l3U6VVSOttIMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization.vue"),
    children: [
  {
    name: "settings-organization-ai",
    path: "ai",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/ai.vue")
  },
  {
    name: users4_45C3PeMMneZZICP8q_H_v5kGKf5BDx8IsyglfcxjW_cMeta?.name,
    path: "users",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/users.vue"),
    children: [
  {
    name: "settings-organization-users",
    path: "",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/users/index.vue")
  },
  {
    name: "settings-organization-users-userId",
    path: ":userId()",
    meta: indextwQzCpFISupx1UgeIaVuAj5r1N4K3ygXtmogJoPtpZMMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/users/[userId]/index.vue")
  },
  {
    name: "settings-organization-users-userId-external",
    path: ":userId()/external",
    meta: externalkmKYlPDqU0dq6Flmsoo01jd9iFo7KlTbjQid9oxfwJIMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/users/[userId]/external.vue")
  },
  {
    name: "settings-organization-users-userId-index.generated",
    path: ":userId()/index.generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/users/[userId]/index.generated.ts")
  },
  {
    name: "settings-organization-users-userId-external.generated",
    path: ":userId()/external.generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/users/[userId]/external.generated.ts")
  }
]
  },
  {
    name: integrationsJ1SxEAUZgWK8v_lEz2a9O_450d6NxIaWHAYxlrwgGfo_454Meta?.name,
    path: "integrations",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/integrations.vue"),
    children: [
  {
    name: "settings-organization-integrations",
    path: "",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/integrations/index.vue")
  },
  {
    name: "settings-organization-integrations-index.generated",
    path: "index.generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/integrations/index.generated.ts")
  },
  {
    name: "settings-organization-integrations-providerId",
    path: ":providerId()",
    meta: indexXIp8s03DCSn0etcIHjPQ43_uQO6fuBFxzI2jdTJEUb8Meta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/integrations/[providerId]/index.vue")
  },
  {
    name: "settings-organization-integrations-providerId-rules",
    path: ":providerId()/rules",
    meta: rules8lyWKmz1pMlKl2LnmeQmquE6EvltdCpencfzC39y5OgMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/integrations/[providerId]/rules.vue")
  },
  {
    name: "settings-organization-integrations-providerId-index.generated",
    path: ":providerId()/index.generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/integrations/[providerId]/index.generated.ts")
  }
]
  },
  {
    name: "settings-organization-localization",
    path: "localization",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/localization.vue")
  },
  {
    name: "settings-organization-authentication",
    path: "authentication",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/authentication.vue")
  },
  {
    name: "settings-organization-investment-mix",
    path: "investment-mix",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/investment-mix.vue")
  }
]
  },
  {
    name: "slides-review-reviewId",
    path: "/slides/review/:reviewId()",
    meta: _91reviewId_93W3B6pr4D2bisCSPvODCWRki81tIGCw7PqPevARg8xh0Meta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/slides/review/[reviewId].vue")
  },
  {
    name: "reset-password-reset-success",
    path: "/reset-password/reset-success",
    meta: reset_45successe3VF464s6lrVsCpE9foZGgVEe6OiUgUJhfv4bDIbI0MMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/reset-password/reset-success.vue")
  },
  {
    name: "teamspace-workspaceId",
    path: "/teamspace/:workspaceId()",
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/index.vue")
  },
  {
    name: "reset-password-request-success",
    path: "/reset-password/request-success",
    meta: request_45success9Y9pyV88WkAHR1IvTk_45LEB7a1mde_lRaW7W2i4tKd3sMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/reset-password/request-success.vue")
  },
  {
    name: "settings-teamspace-teamspaceId",
    path: "/settings/teamspace/:teamspaceId()",
    meta: _91teamspaceId_93PdHayYkssCBrZRZy81BLYkmKo0QXHHq5LfzUEz_cstcMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId].vue"),
    children: [
  {
    name: "settings-teamspace-teamspaceId-general",
    path: "general",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/general.vue")
  },
  {
    name: "settings-teamspace-teamspaceId-investment-mix",
    path: "investment-mix",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/investment-mix.vue")
  },
  {
    name: "settings-teamspace-teamspaceId-general.generated",
    path: "general.generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/general.generated.ts")
  },
  {
    name: "settings-teamspace-teamspaceId-teams-and-members",
    path: "teams-and-members",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/teams-and-members.vue")
  },
  {
    name: "settings-teamspace-teamspaceId-integrations",
    path: "integrations",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/integrations/index.vue")
  },
  {
    name: "settings-teamspace-teamspaceId-teams-and-members.generated",
    path: "teams-and-members.generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/teams-and-members.generated.ts")
  },
  {
    name: "settings-teamspace-teamspaceId-integrations-providerId",
    path: "integrations/:providerId()",
    meta: indexAzjZDpZEuJCaX36OpaQ03lTstPBakJeEEJYvB6_45nkusMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/integrations/[providerId]/index.vue")
  }
]
  },
  {
    name: "reset-password-uid-set-password",
    path: "/reset-password/:uid()/set-password",
    meta: set_45passwordZM1prtMaYFA9_2quNI9xNoOAbh4KsY9VcCz_fuVk8ZEMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/reset-password/[uid]/set-password.vue")
  },
  {
    name: "teamspace-workspaceId-reviews",
    path: "/teamspace/:workspaceId()/reviews",
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/reviews/index.vue")
  },
  {
    name: "teamspace-workspaceId-surveys",
    path: "/teamspace/:workspaceId()/surveys",
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/surveys/index.vue")
  },
  {
    name: "teamspace-workspaceId-dashboards",
    path: "/teamspace/:workspaceId()/dashboards",
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/dashboards/index.vue")
  },
  {
    name: "settings-teamspace-teamspaceId.generated",
    path: "/settings/teamspace/:teamspaceId().generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId].generated.ts")
  },
  {
    name: "teamspace-workspaceId-reviews-reviewId",
    path: "/teamspace/:workspaceId()/reviews/:reviewId()",
    meta: indexcnJ1vOB492TR2rg4WoksFqP3Uy8l1uWf_45m0NKd7URJoMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/reviews/[reviewId]/index.vue")
  },
  {
    name: "teamspace-workspaceId-surveys-surveyId",
    path: "/teamspace/:workspaceId()/surveys/:surveyId()",
    meta: indexFx_1No_XmPUonTOhnTPwFnmMEGoIWbhKN8s9DFSQwy0Meta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/surveys/[surveyId]/index.vue")
  },
  {
    name: "teamspace-workspaceId-dashboards-dashboardId",
    path: "/teamspace/:workspaceId()/dashboards/:dashboardId()",
    meta: indexfetGZawPyl3dmCHrtmweOooBzSI3KKfVe92Kkzp6_nYMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/dashboards/[dashboardId]/index.vue")
  }
]
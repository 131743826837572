import type { WatchHandle } from 'vue'
import type { WsEventType } from '~/codegen/graphql'

export interface WSEvent {
  on: string
  type: WsEventType
  objectId: string
  parentId: string
  actorId: string | null
  contextId: string | null
}

interface EventHandler {
  events: WsEventType[]
  matcher: (data: WSEvent) => boolean
  cb: () => void
  skipOwn?: boolean
}

const currentUserId = ref('')

export default function () {
  const { $ws, $lastWsEvents } = useNuxtApp()
  let unwatch: WatchHandle | null = null

  function watchAndExecute({ events, matcher, cb, skipOwn = true }: EventHandler) {
    unwatch = watch($ws.data, (wsData) => {
      const data = JSON.parse(wsData)
      if (events.includes(data.type) && matcher(data)) {
        if (skipOwn && data.actorId && data.actorId === currentUserId.value) {
          // skip event handling triggered from the current user
          return
        }
        cb()
      }
    })
  }

  onUnmounted(() => {
    if (unwatch) {
      unwatch()
    }
  })

  function openWs(userId: string) {
    $ws.open()
    currentUserId.value = userId
  }

  function closeWs() {
    $ws.close()
  }

  return {
    openWs,
    closeWs,
    watchAndExecute,
    status: $ws.status,
    lastWsEvents: $lastWsEvents,
  }
}

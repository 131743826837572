export default defineAppConfig({
  icon: {
    mode: 'svg',
    class: 'fill-current',
  },
  ui: {
    primary: 'blue',
    gray: 'jumbo',
    notifications: {
      // Show toasts at the top right of the screen
      position: 'top-0 bottom-auto',
    },
    notification: {
      default: {
        closeButton: {
          icon: 'light:xmark',
        },
      },
    },
    breadcrumb: {
      base: 'text-lg',
      li: 'text-blue-600 dark:text-blue-400',
      active: 'text-gray-950 dark:text-gray-100',
    },
    button: {
      color: {
        'primary': {
          solid:
            'shadow-sm text-white bg-primary-600 hover:bg-primary-700 disabled:bg-primary-400',
        },
        'white-nav': {
          ghost: `hover:bg-blue-80 dark:hover:bg-gray-900 dark:text-gray-100 has-[[data-headlessui-state='open']]:bg-blue-80 has-[[data-headlessui-state='open']]:dark:bg-gray-900`,
        },
        'gray': {
          ghost: `hover:bg-blue-80/50 dark:hover:bg-gray-800`,
          secondary: 'bg-white dark:bg-gray-800 shadow-sm border border-gray-200 dark:border-gray-700 hover:bg-blue-80/50 dark:hover:bg-gray-700 disabled:bg-white dark:disabled:bg-gray-800',
        },
        'nav': {
          primary: `text-gray-950 hover:bg-gray-300 group-[[data-headlessui-state='open']]/dropdown:bg-gray-300 dark:text-gray-100 dark:bg-red-950 hover:dark:bg-gray-800 group-[[data-headlessui-state='open']]/dropdown:dark:bg-gray-800`,
        },
      },
      variant: {
        secondary: 'bg-white dark:bg-gray-800 text-gray-950 dark:text-gray-100',
        ghost: 'text-gray-950 dark:text-gray-100',
      },
      size: {
        '2xs': 'text-xs',
        'xs': 'text-xs',
        'sm': 'text-sm',
        'md': 'text-base',
        'lg': 'text-base',
        'xl': 'text-xl',
      },
      default: {
        size: 'md',
        loadingIcon: 'light:spinner-third',
      },
      icon: {
        size: {
          '2xs': 'size-3',
          'xs': 'size-3',
          'sm': 'size-4',
          'md': 'size-5',
          'lg': 'size-5',
          'xl': 'size-6',
        },
      },
    },
    dropdown: {
      background: 'dark:bg-gray-900',
      item: {
        padding: 'p-2',
        icon: {
          base: 'flex-shrink-0 size-4',
          active: 'text-gray-600',
          inactive: 'text-gray-400 dark:text-gray-400',
        },
        active: 'bg-gray-200 dark:bg-gray-700',
      },
    },
    input: {
      color: {
        white: {
          outline: 'dark:ring-gray-700 dark:bg-gray-900',
        },
      },
      icon: {
        size: {
          '2xs': 'size-3',
          'xs': 'size-3',
          'sm': 'size-4',
          'md': 'size-5',
          'lg': 'size-5',
          'xl': 'size-6',
        },
      },
      default: {
        loadingIcon: 'light:spinner-third',
      },
    },
    divider: {
      border: { base: 'border-gray-300 dark:border-gray-800' },
    },
    select: {
      base: '!cursor-pointer disabled:!cursor-not-allowed',
      color: {
        white: {
          outline: 'dark:ring-gray-600 dark:bg-gray-900',
        },
      },
      default: {
        loadingIcon: 'light:spinner-third',
        trailingIcon: 'light:chevron-down',
      },
      icon: {
        size: {
          '2xs': 'size-3',
          'xs': 'size-3',
          'sm': 'size-4',
          'md': 'size-5',
          'lg': 'size-5',
          'xl': 'size-6',
        },
      },
    },
    selectMenu: {
      option: {
        base: '!cursor-pointer',
        selectedIcon: {
          base: 'size-4',
          padding: 'pe-1',
        },
      },
    },
    chip: {
      size: {
        xl: 'text-[9px]',
      },
    },
    verticalNavigation: {
      active: 'before:bg-blue-80 before:dark:bg-gray-900',
      inactive: 'text-gray-950 hover:before:bg-blue-80 dark:hover:before:bg-gray-900',
    },
    card: {
      background: 'bg-gray-100',
      shadow: '',
    },
    tabs: {
      list: {
        marker: {
          background: 'bg-gray-200 dark:bg-gray-900',
        },
      },
    },
    table: {
      th: {
        color: 'text-gray-500',
        padding: 'pl-0 pr-2 last:pr-0 pt-3.5 pb-0.5',
        font: 'font-normal',
      },
      td: {
        color: 'text-gray-900 dark:text-gray-50',
        padding: 'pl-0 pr-2 last:pr-0 py-3.5',
      },
    },
    skeleton: {
      background: 'bg-gray-200',
    },
    inputMenu: {
      default: {
        loadingIcon: 'light:spinner-third',
        trailingIcon: 'light:chevron-down',
      },
    },
    toggle: {
      default: {
        loadingIcon: 'light:spinner-third',
      },
    },
    badge: {
      color: {
        filter: {
          solid: 'bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-200',
        },
      },
    },
  },
})

const _transparent = "transparent"
const _current = "currentColor"
const _black = "#000"
const _gray = {"50":"rgb(var(--color-gray-50) / <alpha-value>)","100":"rgb(var(--color-gray-100) / <alpha-value>)","200":"rgb(var(--color-gray-200) / <alpha-value>)","300":"rgb(var(--color-gray-300) / <alpha-value>)","400":"rgb(var(--color-gray-400) / <alpha-value>)","500":"rgb(var(--color-gray-500) / <alpha-value>)","600":"rgb(var(--color-gray-600) / <alpha-value>)","700":"rgb(var(--color-gray-700) / <alpha-value>)","800":"rgb(var(--color-gray-800) / <alpha-value>)","900":"rgb(var(--color-gray-900) / <alpha-value>)","940":"#212329","950":"rgb(var(--color-gray-950) / <alpha-value>)"}
const _primary = {"50":"rgb(var(--color-primary-50) / <alpha-value>)","80":"#D6D9DD","90":"#E1E9F0","100":"rgb(var(--color-primary-100) / <alpha-value>)","200":"rgb(var(--color-primary-200) / <alpha-value>)","300":"rgb(var(--color-primary-300) / <alpha-value>)","400":"rgb(var(--color-primary-400) / <alpha-value>)","500":"rgb(var(--color-primary-500) / <alpha-value>)","600":"rgb(var(--color-primary-600) / <alpha-value>)","700":"rgb(var(--color-primary-700) / <alpha-value>)","800":"rgb(var(--color-primary-800) / <alpha-value>)","900":"rgb(var(--color-primary-900) / <alpha-value>)","950":"rgb(var(--color-primary-950) / <alpha-value>)","DEFAULT":"rgb(var(--color-primary-DEFAULT) / <alpha-value>)"}
const _white = "#fff"
const _blue = {"50":"#EEF1F5","80":"#D6D9DD","90":"#E1E9F0","100":"#D1E9FF","200":"#B2DDFF","300":"#84CAFF","400":"#53B1FD","500":"#2E90FA","600":"#1570EF","700":"#175CD3","800":"#1849A9","900":"#194185"}
const _jumbo = {"50":"#FAFAFA","100":"#e9eaea","200":"#d4d4d5","300":"#bebfc0","400":"#939497","500":"#7d7e82","600":"#67696d","700":"#515358","800":"#3c3e43","900":"#26282e","940":"#212329","950":"#1a1c21"}
const _red = {"50":"#FFE4EB","100":"#FFD2DD","200":"#FFA5B4","300":"#FF7D87","400":"#FF5050","500":"#E71D32","600":"#AD1625","700":"#8C101C","800":"#6E0A1E","900":"#4C0A17"}
const _green = {"50":"#D2FBD0","100":"#A3F7A9","200":"#72E985","300":"#4DD470","400":"#1CB854","500":"#149E53","600":"#0E8450","700":"#086A49","800":"#055843","900":"#044837"}
const _yellow = {"50":"#FFFAED","100":"#FFF4DB","200":"#FFE9B7","300":"#FEDE93","400":"#FED36F","500":"#FEC84B","600":"#E5B444","700":"#CBA03C","800":"#B28C35","900":"#98782D"}
const _purple = {"100":"#E3DAF8","400":"#8E69E2","600":"#7244DB"}
const _orange = {"600":"#FE9029"}
const _success = {"50":"#D2FBD0","100":"#A3F7A9","200":"#72E985","300":"#4DD470","400":"#1CB854","500":"#149E53","600":"#0E8450","700":"#086A49","800":"#055843","900":"#044837"}
const _warning = {"50":"#FFFAED","100":"#FFF4DB","200":"#FFE9B7","300":"#FEDE93","400":"#FED36F","500":"#FEC84B","600":"#E5B444","700":"#CBA03C","800":"#B28C35","900":"#98782D"}
const _error = {"50":"#FFE4EB","100":"#FFD2DD","200":"#FFA5B4","300":"#FF7D87","400":"#FF5050","500":"#E71D32","600":"#AD1625","700":"#8C101C","800":"#6E0A1E","900":"#4C0A17"}
const _cool = {"50":"#f9fafb","100":"#f3f4f6","200":"#e5e7eb","300":"#d1d5db","400":"#9ca3af","500":"#6b7280","600":"#4b5563","700":"#374151","800":"#1f2937","900":"#111827","950":"#030712"}
const config = { "transparent": _transparent, "current": _current, "black": _black, "gray": _gray, "primary": _primary, "white": _white, "blue": _blue, "jumbo": _jumbo, "red": _red, "green": _green, "yellow": _yellow, "purple": _purple, "orange": _orange, "success": _success, "warning": _warning, "error": _error, "cool": _cool,  }
export { config as default, _transparent, _current, _black, _gray, _primary, _white, _blue, _jumbo, _red, _green, _yellow, _purple, _orange, _success, _warning, _error, _cool }